import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getProductFilesArray,
  getFileExtensionsString,
  getFileExtension,
  checkIfFileAllowed,
  sanitizeFileName,
  checkIsBOI,
} from "_helpers";

import { customerActions, alertActions } from "_actions";

import ConfirmationModal from "components/ConfirmationModal";

const codesToHide = ["TRADEMARK_MAIL"];

const FormPDF = ({ productDetails, isDirty, onShowConfirmation }) => {
  if (!productDetails) return null;

  const dispatch = useDispatch();
  const orderData = useSelector(({ customers: { orderdata } }) => orderdata);
  const prevProductDetails = orderData?.products?.find(({ code }) => code?.code === productDetails?.code?.code);

  const pdfEl = useRef(null);
  const orderId = productDetails?.orderId;
  const productId = productDetails?.id;

  const processingResult = productDetails.processingResult;
  const zipFileName = `${productDetails.firstName} ${productDetails.lastName}`;

  const uploadedFileMap = getProductFilesArray(productDetails)?.filter((file) => !codesToHide.includes(file?.type));
  const isNoFiles = !uploadedFileMap.length;

  const { productsFileTypes, productsFileTypesObj } = useSelector(({ variants }) => variants);

  const productFileTypes = productsFileTypes[productDetails?.code?.code] || {};

  const [fileToDelete, setFileToDelete] = useState(null);
  const [showForceUploadModal, setShowForceUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [type, setType] = useState("");
  const [forceMimeCheck, setForceMimeCheck] = useState(false);

  const productFileTypesObj = productsFileTypesObj[productDetails?.code?.code] || {};
  const extensionsFile = getFileExtensionsString(productFileTypesObj[type]?.allowedExtensions);
  const selectedFileSize = selectedFile?.size;
  const selectedFileExt = getFileExtension(selectedFile?.name);
  const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, productFileTypesObj[type]?.allowedExtensions);
  const hasUploadableOptions = Boolean(
    !!productFileTypes &&
      processingResult?.productResultFileType.filter((key) => productFileTypes[key] && !codesToHide.includes(key))
        ?.length
  );

  const handlePDFChange = (event) => {
    if (!event.target.files.length) {
      setSelectedFile(null);
    } else {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDownloadProductResultPDF = (fileKey, filename) =>
    dispatch(customerActions.downloadProductPDF(orderId, productId, fileKey, filename, forceMimeCheck));

  const handleProductPDFDelete = (fileKey) => dispatch(customerActions.deleteProductPDF(orderId, productId, fileKey));

  const handleDownloadOrderFilesZip = () =>
    dispatch(customerActions.downloadAllProductFiles(orderId, productId, zipFileName));

  const handleProductPDFUpload = (force = false) => {
    const uploadFunction = () => {
      const formData = new FormData();

      formData.append("file", selectedFile, sanitizeFileName(selectedFile?.name));

      dispatch(customerActions.uploadProductPDF(orderId, productId, type, formData));
      setSelectedFile(null);
      if (pdfEl.current) pdfEl.current.value = null;
    };

    if (force) {
      if (
        type === "BOI_ID" &&
        checkIsBOI(productDetails?.code?.code) &&
        (!prevProductDetails?.driverLicenceOrID || !prevProductDetails?.dateOfBirth)
      ) {
        if (!prevProductDetails?.driverLicenceOrID && !prevProductDetails?.dateOfBirth) {
          dispatch(
            alertActions.error(
              `Please provide customer's date of birth and Driver's License or ID Number before uploading BOI ID`
            )
          );
        }
        if (!prevProductDetails?.driverLicenceOrID && prevProductDetails?.dateOfBirth) {
          dispatch(alertActions.error("Please provide Driver's License or ID Number before uploading BOI ID"));
        }
        if (!prevProductDetails?.dateOfBirth && prevProductDetails?.driverLicenceOrID) {
          dispatch(alertActions.error("Please provide Date of Birth on Customer Tab before uploading BOI ID"));
        }
      } else if (selectedFile === null) {
        dispatch(alertActions.error("Please select file to upload"));
      } else {
        uploadFunction();
      }
    } else {
      if (
        type === "BOI_ID" &&
        checkIsBOI(productDetails?.code?.code) &&
        (!prevProductDetails?.driverLicenceOrID || !prevProductDetails?.dateOfBirth)
      ) {
        if (!prevProductDetails?.driverLicenceOrID && !prevProductDetails?.dateOfBirth) {
          dispatch(
            alertActions.error(
              `Please provide customer's date of birth and Driver's License or ID Number before uploading BOI ID`
            )
          );
        }
        if (!prevProductDetails?.driverLicenceOrID && prevProductDetails?.dateOfBirth) {
          dispatch(alertActions.error("Please provide Driver's License or ID Number before uploading BOI ID"));
        }
        if (!prevProductDetails?.dateOfBirth && prevProductDetails?.driverLicenceOrID) {
          dispatch(alertActions.error("Please provide Date of Birth on Customer Tab before uploading BOI ID"));
        }
      } else if (!isExtensionAllowed) {
        dispatch(alertActions.error("Please select file with correct extension: " + (extensionsFile || "any type")));
      } else if (selectedFileSize && selectedFileSize / 1024 / 1024 > 5) {
        dispatch(alertActions.error("File size limit is 5MB"));
      } else if (selectedFile === null) {
        dispatch(alertActions.error("Please select file to upload"));
      } else if (isDirty) {
        onShowConfirmation(uploadFunction);
      } else {
        uploadFunction();
      }
    }
  };

  useEffect(() => {
    productFileTypes &&
      setType(
        productDetails.processingResult.productResultFileType.filter(
          (key) => !codesToHide.includes(key) && productFileTypes[key]
        )[0]
      );
  }, [productsFileTypes]);

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Uploaded Documents</h6>

      <Table responsive>
        <thead>
          <tr>
            <th>Type</th>
            <th>Uploaded</th>
            <th>Action</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!isNoFiles && (
            <>
              {uploadedFileMap.map((file) => (
                <tr key={file?.objKey}>
                  <td>
                    <div>{file?.ourFileName}</div>
                    <div style={{ color: "gray", fontSize: "12px" }}>
                      {productFileTypesObj[file?.type]?.customersName?.replace(/_/g, " ")}
                    </div>
                  </td>
                  <td>{file?.uploaded ? moment.utc(file?.uploaded).local().format("YYYY/MM/DD HH:mm:ss") : "N/A"}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="info"
                      className="w-100"
                      onClick={() => handleDownloadProductResultPDF(file?.objKey, file?.ourFileName)}
                    >
                      Download
                    </Button>
                  </td>
                  <td>
                    <Button size="sm" variant="danger" className="w-100" onClick={() => setFileToDelete(file)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
              {productDetails.code.category === "VITAL" && (
                <tr>
                  <td>Download Product Files:</td>
                  <td />
                  <td colSpan={2}>
                    <Button variant="info" size="sm" value={orderId} onClick={handleDownloadOrderFilesZip}>
                      Download
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td
                  colSpan={2}
                  title="Some files from old orders may be downloaded with the wrong file type. If you found such a file, try to check the box and download it again."
                >
                  Please, check this box if files are downloaded with the wrong file type&nbsp;
                  <i style={{ color: "gray", fontSize: "1.35em" }} className="fa fa-info-circle" />
                </td>
                <td colSpan={2}>
                  <Form.Check
                    type="checkbox"
                    label="Force check file type"
                    checked={forceMimeCheck}
                    onChange={() => setForceMimeCheck(!forceMimeCheck)}
                  />
                </td>
              </tr>
            </>
          )}
          {isNoFiles && (
            <tr>
              <td>No files</td>
            </tr>
          )}
          {hasUploadableOptions && (
            <>
              <tr>
                <td>
                  <input
                    style={{ border: "2px solid lightgray", borderRadius: "4px", padding: "8px", cursor: "pointer" }}
                    ref={pdfEl}
                    className="mb-1"
                    type="file"
                    accept={extensionsFile}
                    onChange={handlePDFChange}
                  />
                </td>
                <td>
                  <Form.Control as="select" size="sm" value={type} onChange={({ target: { value } }) => setType(value)}>
                    {!!productFileTypes &&
                      processingResult.productResultFileType
                        .filter((key) => productFileTypes[key] && !codesToHide.includes(key))
                        .map((key) => (
                          <option key={key} value={key}>
                            {productFileTypesObj[key]?.customersName?.replace("TRADEMARK_", "").replace(/_/g, " ")}
                          </option>
                        ))}
                  </Form.Control>
                </td>
                <td>
                  <Button
                    variant="success"
                    className="my-1 w-100"
                    size="sm"
                    disabled={!selectedFile}
                    onClick={() => handleProductPDFUpload(false)}
                  >
                    Upload
                  </Button>
                </td>
                <td>
                  <Button
                    variant="warning"
                    className="my-1 w-100"
                    size="sm"
                    title="Try to upload without file type checking"
                    disabled={!selectedFile}
                    onClick={() => setShowForceUploadModal(true)}
                  >
                    Force Upload
                  </Button>
                </td>
              </tr>
              <tr>
                <td style={{ border: 0, paddingTop: 0 }}>
                  <span className="allowed-file-types-label">
                    Allowed file types:&nbsp;
                    {extensionsFile || (
                      <span title="Allowed file types not provided, try f.e. [.pdf .png .jpg .jpeg]">
                        any type <i style={{ fontSize: "1.35em" }} className="fa fa-info-circle" />
                      </span>
                    )}
                  </span>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>

      <ConfirmationModal
        show={Boolean(fileToDelete)}
        handleClose={() => {
          setFileToDelete(null);
        }}
        handleConfirm={() => {
          handleProductPDFDelete(fileToDelete?.objKey);
          setFileToDelete(null);
        }}
        body={
          <div>
            <div>{` Are you sure you want to delete "${fileToDelete?.ourFileName}"?`}</div>
            <div style={{ color: "gray" }}>
              {`[Uploaded: ${
                fileToDelete?.uploaded
                  ? moment.utc(fileToDelete?.uploaded).local().format("YYYY/MM/DD HH:mm:ss")
                  : "N/A"
              }]`}
            </div>
          </div>
        }
      />

      <ConfirmationModal
        show={Boolean(fileToDelete)}
        handleClose={() => {
          setFileToDelete(null);
        }}
        handleConfirm={() => {
          handleProductPDFDelete(fileToDelete?.objKey);
          setFileToDelete(null);
        }}
        body={
          <div>
            <div>{` Are you sure you want to delete "${fileToDelete?.ourFileName}"?`}</div>
            <div style={{ color: "gray" }}>
              {`[Uploaded: ${
                fileToDelete?.uploaded
                  ? moment.utc(fileToDelete?.uploaded).local().format("YYYY/MM/DD HH:mm:ss")
                  : "N/A"
              }]`}
            </div>
          </div>
        }
      />

      <ConfirmationModal
        show={showForceUploadModal}
        handleClose={() => {
          setShowForceUploadModal(false);
        }}
        handleConfirm={() => {
          handleProductPDFUpload(true);
          setShowForceUploadModal(false);
        }}
        body={
          <div>
            <div>{` Are you sure you want to force upload "${selectedFile?.name}" without any types checking?`}</div>
            <br />
            <div>
              <i className="fa fa-exclamation-circle text-danger" />
              &nbsp;
              {`It may return errors from server or will not work correctly.`}
            </div>
          </div>
        }
      />
    </>
  );
};

export default FormPDF;
