import { Row, Form } from "react-bootstrap";

import { phoneFormatter } from "_helpers";

import IndividualAddressView from "../IndividualAddress/View";

export default function Individual({ individual }) {
  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.firstName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional)
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.middleName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.lastName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional)
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.suffixName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone Number
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(individual?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email Address
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.email || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Country Of Citizenship
        </Form.Label>
        <Form.Label column sm={7}>
          {individual?.countryOfCitizenship || "–"}
        </Form.Label>
      </Form.Group>

      <IndividualAddressView address={individual?.address} />
    </>
  );
}
