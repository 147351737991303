import { Col, Form, Row } from "react-bootstrap";

import IndividualEdit from "../Individual/Edit";
import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormIncorpifyAmendment({ orderDetails, selectedProduct, onChange, onError }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.amendmentInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleProductIndividualChange = (value) => handleProductChange({ target: { name: "individual", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <hr />
      <h6 className="font-weight-bold">Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="businessName"
            value={productDetails?.businessName || ""}
            onChange={handleProductChange}
            onInvalid={() => onError("Business Name")}
          />
        </Col>
      </Form.Group>

      <IndividualEdit
        individual={productDetails.individual}
        onError={handleAddError}
        onChange={handleProductIndividualChange}
      />
    </div>
  );
}
