import { customerConstants } from "_constants";
import { handleError, history } from "_helpers";
import { customerService } from "_services";
import { config } from "_config";
import { alertActions } from "./";

export const customerActions = {
  loginCustomer,
  // getAll,
  searchcustomer,
  exportCustomer,
  getOrder,
  deleteOrder,
  addNote,
  getAvailableStatuses,
  downloadPDF,
  downloadProductPDF,
  uploadPDF,
  uploadProductPDF,
  deleteProductPDF,
  updateorder,
  setPassword,
  chargeUSPTOFee,
  chargeAmendment,
  refunds,
  refundLastTransaction,
  resendEmailByKey,
  sendReceipt,
  sendhardcopy,
  getsummary,
  getprimaryactivity,
  automationLock,
  getdashboardreport,
  getTrackingInfo,
  downloadAllProductFiles,
  unsubscribeProducts,
  setCollapseFederalSearch,
  setCollapseStateSearch,
  setCollapseMonitoringReport,
  getPaymentMethods,
  addPaymentMethod,
  setPaymentDetails,
  getSubscriptionDetails,
  rerunSubscription,
  chargeAnnualComplianceReport,
  setCollapseMonitoringReport,
  chargeSOUFee,
  chargeExtensionFee,
  chargeRevivalFromAbandoment,
  chargeSalesTaxPermitStateFee,
  dissolveOrder,
  clearCustomerInfo,
  addRelatedOrder,
  getOrderRelatedOrders,
  createDelayedUpsell,
  chargeOfficeAction,
  unlockAutomationAction,
  changeEmail,
};

function loginCustomer({ orderId, username, salePageURL }) {
  return (dispatch) => {
    customerService.loginCustomer(username).then(
      (customer) => {
        let url = `${salePageURL}/login?token=${customer?.token}`;

        if (orderId) {
          url += `&orderId=${orderId}`;
        }

        window?.open(url, "_blank");
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

// function getAll(options) {
//   return (dispatch) => {
//     dispatch(request());
//
//     customerService.getAll(options).then(
//       (orders) => {
//         dispatch(success(orders));
//       },
//       (error) => {
//         dispatch(failure());
//         dispatch(alertActions.error(error?.message || error?.toString()));
//       }
//     );
//   };
//
//   function request() {
//     return { type: customerConstants.GET_ORDERS_REQUEST };
//   }
//   function success(payload) {
//     return { type: customerConstants.GET_ORDERS_SUCCESS, payload };
//   }
//   function failure(payload) {
//     return { type: customerConstants.GET_ORDERS_FAILURE, payload };
//   }
// }

function searchcustomer(options) {
  return (dispatch) => {
    dispatch(request());

    customerService.searchcustomer(options).then(
      (orders) => dispatch(success(orders)),
      (error) => {
        dispatch(failure(error?.toString()));
        dispatch(
          alertActions.error(error?.message || (Array.isArray(error?.errors) && error.errors[0]) || error?.toString())
        );
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_ORDERS_REQUEST };
  }
  function success(payload) {
    return { type: customerConstants.GET_ORDERS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: customerConstants.GET_ORDERS_FAILURE, payload };
  }
}

function exportCustomer(options) {
  return (dispatch) => {
    dispatch(request());

    customerService.exportCustomer(options).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_EXPORT_ORDERS_REQUEST };
  }
  function success() {
    return { type: customerConstants.GET_EXPORT_ORDERS_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.GET_EXPORT_ORDERS_FAILURE };
  }
}

function getOrder(id, isUpdate = false) {
  return (dispatch) => {
    dispatch(request());

    customerService.getOrder(id).then(
      (order) => {
        // const monitoringProductIndex = order?.products?.findIndex(
        //   ({ code }) => code?.code === "TRADEMARK_Trademark_Monitoring"
        // );

        // if (monitoringProductIndex !== -1)
        //   dispatch(getSubscriptionDetails(order?.order?.id, order?.products[monitoringProductIndex]?.id));

        let flag = false;
        const filteredProducts = order?.products.filter((product) => {
          if (product?.code === null || product?.code === undefined) {
            flag = true;
            return false;
          }

          return true;
        });

        if (flag) {
          order.products = filteredProducts;
        }

        order?.products.forEach((product) => {
          if (product.code.subscription) {
            dispatch(getSubscriptionDetails(order?.order?.id, product?.id));
          }
        });

        if (!isUpdate) dispatch(getOrderRelatedOrders(order, true));

        if (order?.customer?.id) {
          dispatch(getPaymentMethods(order?.customer?.id));
        }

        dispatch(success(order));
      },
      (error) => {
        history.replace("/");
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_ORDER_REQUEST };
  }
  function success(payload) {
    return { type: customerConstants.GET_ORDER_SUCCESS, payload };
  }
  function failure() {
    return { type: customerConstants.GET_ORDER_FAILURE };
  }
}

function deleteOrder(id, cb) {
  return (dispatch) => {
    dispatch(request());

    customerService.deleteOrder(id).then(
      () => {
        cb();
        dispatch(alertActions.success("Order was successfully deleted."));
        dispatch(success());
      },
      (error) => {
        console.log("ERROR", error);
        dispatch(
          alertActions.error(
            error?.message || error?.toString() || "An error has occurred. The order has not been deleted."
          )
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: customerConstants.DELETE_ORDER_REQUEST };
  }
  function success() {
    return { type: customerConstants.DELETE_ORDER_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.DELETE_ORDER_FAILURE };
  }
}

function addNote(orderId, data) {
  return (dispatch) => {
    dispatch(request());

    customerService.addNote(orderId, data).then(
      () => {
        dispatch(customerActions.getOrder(orderId));
        dispatch(alertActions.success("Note was successfully added."));
        dispatch(success());
      },
      (error) => {
        console.log("ERROR", error);
        dispatch(
          alertActions.error(
            error?.message || error?.toString() || "An error has occurred. The note has not been added."
          )
        );
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: customerConstants.POST_NOTE_REQUEST };
  }
  function success() {
    return { type: customerConstants.POST_NOTE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.POST_NOTE_FAILURE };
  }
}

function getAvailableStatuses(category, productId) {
  return (dispatch) => {
    dispatch(request());

    customerService.getAvailableStatuses(category, productId).then(
      (statuses) => {
        dispatch(success(statuses));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_AVAILABLE_STATUSES_REQUEST };
  }
  function success(payload) {
    return { type: customerConstants.GET_AVAILABLE_STATUSES_SUCCESS, payload };
  }
  function failure() {
    return { type: customerConstants.GET_AVAILABLE_STATUSES_FAILURE };
  }
}

function getOrderRelatedOrders(order, includeTest = false, page = 1) {
  return (dispatch) => {
    dispatch(request());

    customerService.getrelatedOrders(order.customer.id, includeTest, page).then(
      (orders) => dispatch(success(orders)),
      () => {
        dispatch(failure());
        dispatch(alertActions.error("Error loading related orders"));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_RELATED_ORDERS_REQUEST };
  }
  function success(payload) {
    return { type: customerConstants.GET_RELATED_ORDERS_SUCCESS, payload };
  }
  function failure() {
    return { type: customerConstants.GET_RELATED_ORDERS_FAILURE };
  }
}

function addRelatedOrder(orderId, data) {
  return (dispatch) => {
    dispatch(request());

    customerService.addRelatedOrder(orderId, data).then(
      () => {
        dispatch(success());
        history.push(`/admin/orders/${orderId}`);
        dispatch(alertActions.success("Order was successfully added"));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.ADD_RELATED_ORDER_REQUEST };
  }
  function success() {
    return { type: customerConstants.ADD_RELATED_ORDER_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.ADD_RELATED_ORDER_FAILURE };
  }
}

function updateorder(id, data, cb) {
  return (dispatch) => {
    dispatch(request());

    customerService.updateorder(id, data).then(
      () => {
        if (cb) cb();
        else dispatch(getOrder(id, true));

        dispatch(success());
        dispatch(alertActions.success("Order updated."));
      },
      (error) => {
        if (typeof error === "string") dispatch(alertActions.error(error?.toString()));
        else dispatch(alertActions.error(error.errors[0]));
        if (typeof error === "string" && error === "Email is busy") {
          dispatch(failure({ retryAction: { type: customerConstants.PUT_ORDER_FAILURE, payload: { id, data, cb } } }));
        } else {
          dispatch(getOrder(id, true));
          dispatch(failure());
        }
      }
    );
  };

  function request() {
    return { type: customerConstants.PUT_ORDER_REQUEST };
  }
  function success() {
    return { type: customerConstants.PUT_ORDER_SUCCESS };
  }
  function failure(payload) {
    return { type: customerConstants.PUT_ORDER_FAILURE, payload };
  }
}

function getprimaryactivity(category) {
  return (dispatch) => {
    customerService.getprimaryactivity(category).then(
      (activity) => dispatch(success(activity)),
      () => {}
    );
  };

  function success(activity) {
    return { type: customerConstants.GETPRIMARYACTIVITY_SUCCESS, activity };
  }
}

function getsummary() {
  return (dispatch) => {
    customerService.getsummary().then(
      (orders) => dispatch(success(orders)),
      (error) => dispatch(failure(error?.message || error?.toString()))
    );
  };
  function success(orders) {
    return { type: customerConstants.GETSUMMARY_SUCCESS, orders };
  }
  function failure(error) {
    return { type: customerConstants.GETSUMMARY_FAILED, error };
  }
}

function setPassword(customerId, password) {
  return (dispatch) => {
    customerService.setPassword(customerId, password).then(
      () => {
        dispatch(alertActions.success("Temporary password created successfully."));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function chargeUSPTOFee(id, amount) {
  return (dispatch) => {
    customerService.chargeUSPTOFee(id, amount).then(
      (data) => {
        if (data?.code === 204) {
          dispatch(alertActions.success(data?.message));
        } else {
          dispatch(alertActions.success("USPTO Fee Charged successfully."));
        }
        dispatch(customerActions.getOrder(id));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function chargeAmendment(id, amount) {
  return (dispatch) => {
    customerService.chargeAmendment(id, amount).then(
      (data) => {
        if (data?.code === 204) {
          dispatch(alertActions.success(data?.message));
        } else {
          dispatch(alertActions.success("Amendment Charged successfully."));
        }
        dispatch(customerActions.getOrder(id));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function refunds({ orderId, productId, amount, cb }) {
  return (dispatch) => {
    customerService.refunds({ orderId, productId, amount }).then(
      () => {
        dispatch(alertActions.success("Refunded successfully."));
        // dispatch(customerActions.getOrder(orderId));
        cb && cb();
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString() || `Something went wrong...`));
      }
    );
  };
}

function refundLastTransaction(orderId, productId) {
  return (dispatch) => {
    customerService.refundLastTransaction(orderId, productId).then(
      () => {
        dispatch(alertActions.success("Product cancelled and transaction refunded successfully."));
        dispatch(customerActions.getOrder(orderId));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function downloadProductPDF(orderId, productId, type, name, checkMime) {
  return (dispatch) => {
    customerService.downloadProductResultPDF(orderId, productId, type, name, checkMime).then(
      () => {},
      (error) => {
        console.log(error.toString());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function downloadPDF(orderId, productId, name, checkMime) {
  return (dispatch) => {
    customerService.downloadPDF(orderId, productId, name, checkMime).then(
      () => {},
      (error) => dispatch(failure(error?.message || error?.toString()))
    );
  };

  function failure(error) {
    return { type: customerConstants.GETALL_FAILURE, error };
  }
}

function resendEmailByKey(orderId, productId, emailKey) {
  return (dispatch) => {
    customerService.resendEmailByKey(orderId, productId, emailKey).then(
      (success) => {
        dispatch(alertActions.success("Email has been sent"));
        dispatch(customerActions.getOrder(orderId));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function sendReceipt(orderId) {
  return (dispatch) => {
    customerService.sendReceipt(orderId).then(
      (success) => {
        dispatch(alertActions.success("Receipt has been sent"));
        dispatch(customerActions.getOrder(orderId));
      },
      (error) => {
        console.log({ error });
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function sendhardcopy(id) {
  return (dispatch) => {
    customerService.sendhardcopy(id).then(
      () => {
        dispatch(alertActions.success("Email has been sent"));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function uploadPDF(id, ein_number, form) {
  return (dispatch) => {
    dispatch(request());

    customerService.uploadPDF(id, ein_number, form).then(
      () => {
        dispatch(getOrder(id, true));

        dispatch(alertActions.success("File uploaded successfully."));
        dispatch(success());
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: customerConstants.POST_ORDER_FILE_REQUEST };
  }
  function success() {
    return { type: customerConstants.POST_ORDER_FILE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.POST_ORDER_FILE_FAILURE };
  }
}

function uploadProductPDF(orderId, productId, type, form, note) {
  return (dispatch) => {
    dispatch(request());

    customerService.uploadProductPDF(orderId, productId, type, form, note).then(
      () => {
        dispatch(getOrder(orderId, true));

        dispatch(alertActions.success("File uploaded successfully."));
        dispatch(success());
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: customerConstants.POST_ORDER_FILE_REQUEST };
  }
  function success() {
    return { type: customerConstants.POST_ORDER_FILE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.POST_ORDER_FILE_FAILURE };
  }
}

function deleteProductPDF(orderId, productId, type) {
  return (dispatch) => {
    dispatch(request());

    customerService.deleteProductPDF(orderId, productId, type).then(
      () => {
        dispatch(getOrder(orderId, true));

        dispatch(alertActions.success("File deleted successfully."));
        dispatch(success());
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: customerConstants.DELETE_ORDER_FILE_REQUEST };
  }
  function success() {
    return { type: customerConstants.DELETE_ORDER_FILE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.DELETE_ORDER_FILE_FAILURE };
  }
}

function automationLock(orderId, productId) {
  return (dispatch) => {
    customerService.automationLock(orderId, productId).then(
      (response) => {
        dispatch(alertActions.success("Operation successfull."));
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };
}

function getdashboardreport({ startDate, endDate, category, zone, includeRecurring }) {
  return (dispatch) => {
    dispatch(request());

    customerService.getdashboardreport({ startDate, endDate, category, zone, includeRecurring }).then(
      (customers) => dispatch(success(customers)),
      (error) => {
        dispatch(failure(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_DASHBOARD_REPORT_REQUEST };
  }

  function success(reportdata) {
    return { type: customerConstants.GET_DASHBOARD_REPORT_SUCCESS, reportdata };
  }

  function failure(error) {
    return { type: customerConstants.GET_DASHBOARD_REPORT_FAILED, error };
  }
}

function getTrackingInfo(orderId) {
  return (dispatch) => {
    dispatch(request());

    customerService.getTrackingInfo(orderId).then(
      (trackingInfo) => dispatch(success(trackingInfo)),
      (error) => dispatch(failure(error?.message || error?.toString()))
    );
  };
  function request() {
    return { type: customerConstants.GET_TRACKING_REQUEST };
  }

  function success(trackingInfo) {
    return { type: customerConstants.GET_TRACKING_SUCCESS, trackingInfo };
  }

  function failure(error) {
    return { type: customerConstants.GET_TRACKING_FAILED, error };
  }
}

function downloadAllProductFiles(orderId, productId, name) {
  return (dispatch) => {
    customerService.downloadAllProductFiles(orderId, productId, name).then(
      () => {},
      (error) => dispatch(alertActions.error(error?.message || error?.toString()))
    );
  };
}

function unsubscribeProducts(orderId, productIds) {
  return (dispatch) => {
    dispatch(request());

    const requests = productIds.map((id) => customerService.postUnsubscribe(orderId, id));

    Promise.all(requests)
      .then((data) => {
        dispatch(success(data));
        dispatch(getOrder(orderId));
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error?.message || error?.toString()));
      });
  };

  function request() {
    return { type: customerConstants.UNSUBSCRIBE_REQUEST };
  }

  function success(payload) {
    return { type: customerConstants.UNSUBSCRIBE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: customerConstants.UNSUBSCRIBE_FAILED, error };
  }
}

function getSubscriptionDetails(orderId, productId) {
  return (dispatch) => {
    dispatch(request());

    customerService.getSubscriptionDetails(orderId, productId).then(
      (subscriptionDetails) => {
        dispatch(success({ productId, subscriptionDetails }));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_SUBSCRIPTION_DETAILS_REQUEST };
  }
  function success(payload) {
    return { type: customerConstants.GET_SUBSCRIPTION_DETAILS_SUCCESS, payload };
  }
  function failure() {
    return { type: customerConstants.GET_SUBSCRIPTION_DETAILS_FAILED };
  }
}

function rerunSubscription(orderId, productId) {
  return (dispatch) => {
    dispatch(request());

    customerService.rerunSubscription(orderId, productId).then(
      () => {
        dispatch(success());
        // dispatch(getSubscriptionDetails(orderId, productId));
        dispatch(getOrder(orderId));
        dispatch(alertActions.success("Payment approved."));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.RERUN_SUBSCRIPTION_REQUEST };
  }
  function success() {
    return { type: customerConstants.RERUN_SUBSCRIPTION_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.RERUN_SUBSCRIPTION_FAILED };
  }
}

function setCollapseFederalSearch(idx) {
  return (dispatch) => dispatch({ type: customerConstants.SET_COLLAPSE_FEDERAL_SEARCH, payload: idx });
}

function setCollapseStateSearch(idx) {
  return (dispatch) => dispatch({ type: customerConstants.SET_COLLAPSE_STATE_SEARCH, payload: idx });
}

function setCollapseMonitoringReport(idx) {
  return (dispatch) => dispatch({ type: customerConstants.SET_COLLAPSE_MONITORING_REPORT, payload: idx });
}

function getPaymentMethods(customerId) {
  return (dispatch) => {
    dispatch(request());

    customerService.getPaymentMethods(customerId).then(
      (paymentMethods) => {
        dispatch(success(paymentMethods));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.GET_PAYMENT_METHODS_REQUEST };
  }
  function success(payload) {
    return { type: customerConstants.GET_PAYMENT_METHODS_SUCCESS, payload };
  }
  function failure() {
    return { type: customerConstants.GET_PAYMENT_METHODS_FAILED };
  }
}

function addPaymentMethod(customerId, cardData) {
  return (dispatch) => {
    dispatch(request());

    customerService.addPaymentMethod(customerId, cardData).then(
      () => {
        dispatch(success());
        dispatch(customerActions.getPaymentMethods(customerId));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.POST_PAYMENT_METHOD_REQUEST };
  }
  function success() {
    return { type: customerConstants.POST_PAYMENT_METHOD_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.POST_PAYMENT_METHOD_FAILED };
  }
}

function setPaymentDetails(orderId, paymentDetails) {
  return (dispatch) => {
    dispatch(request());

    customerService.setPaymentDetails(orderId, paymentDetails).then(
      () => {
        dispatch(success());
        dispatch(alertActions.success("Successfully processed"));
        dispatch(getOrder(orderId));
      },
      (error) => {
        dispatch(failure());

        if (Array.isArray(error?.errors)) {
          error?.errors.forEach((errorItem) => {
            dispatch(alertActions.error(errorItem?.message || errorItem?.toString()));
          });
        } else {
          dispatch(alertActions.error(error?.message || error?.toString()));
        }
      }
    );
  };

  function request() {
    return { type: customerConstants.POST_PAYMENT_DETAILS_REQUEST };
  }
  function success() {
    return { type: customerConstants.POST_PAYMENT_DETAILS_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.POST_PAYMENT_DETAILS_FAILED };
  }
}

function chargeAnnualComplianceReport(orderId, amount) {
  return (dispatch) => {
    dispatch(request());

    customerService.chargeAnnualComplianceReport(orderId, amount).then(
      (response) => {
        if (response && Object.keys(response)?.includes("success")) {
          if (!response?.success) {
            dispatch(failure());
            dispatch(alertActions.error(response?.message || "Something went wrong..."));
          } else {
            dispatch(success());
            dispatch(alertActions.success(response?.message || "Successfully charged"));
          }
        } else {
          dispatch(success());
          dispatch(alertActions.success("Charge request sended"));
        }
        dispatch(getOrder(orderId));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHARGE_ANNUAL_COMPLIANCE_REPORT_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHARGE_ANNUAL_COMPLIANCE_REPORT_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHARGE_ANNUAL_COMPLIANCE_REPORT_FAILURE };
  }
}

function chargeSOUFee(orderId) {
  return (dispatch) => {
    dispatch(request());

    customerService.chargeSOUFee(orderId).then(
      (response) => {
        if (!response.success) {
          dispatch(failure());
          dispatch(alertActions.error(response.message || "Something went wrong..."));
        } else {
          dispatch(success());
          dispatch(alertActions.success(response.message || "Successfully charged"));
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHARGE_SOU_FEE_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHARGE_SOU_FEE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHARGE_SOU_FEE_FAILURE };
  }
}

function chargeExtensionFee(orderId) {
  return (dispatch) => {
    dispatch(request());

    customerService.chargeExtensionFee(orderId).then(
      (response) => {
        if (!response.success) {
          dispatch(failure());
          dispatch(alertActions.error(response.message || "Something went wrong..."));
        } else {
          dispatch(success());
          dispatch(alertActions.success(response.message || "Successfully charged"));
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHARGE_EXTENSION_FEE_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHARGE_EXTENSION_FEE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHARGE_EXTENSION_FEE_FAILURE };
  }
}

function chargeOfficeAction(orderId) {
  return (dispatch) => {
    dispatch(request());

    customerService.chargeOfficeAction(orderId).then(
      (response) => {
        if (!response.success) {
          dispatch(failure());
          dispatch(alertActions.error(response.message || "Something went wrong..."));
        } else {
          dispatch(success());
          dispatch(alertActions.success(response.message || "Successfully charged"));
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHARGE_OFFICE_ACTION_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHARGE_OFFICE_ACTION_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHARGE_OFFICE_ACTION_FAILURE };
  }
}

function chargeRevivalFromAbandoment(orderId, numberOfClasses) {
  return (dispatch) => {
    dispatch(request());

    customerService.chargeRevivalFromAbandoment(orderId, numberOfClasses).then(
      (response) => {
        if (response?.success) {
          dispatch(success());
          dispatch(alertActions.success(response?.message || "Successfully charged"));
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response?.message || "Something went wrong..."));
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHARGE_REVIVAL_FROM_ABANDOMENT_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHARGE_REVIVAL_FROM_ABANDOMENT_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHARGE_REVIVAL_FROM_ABANDOMENT_FAILURE };
  }
}

function chargeSalesTaxPermitStateFee(orderId, amount) {
  return (dispatch) => {
    dispatch(request());

    customerService.chargeSalesTaxPermitStateFee(orderId, amount).then(
      (response) => {
        if (response && Object.keys(response)?.includes("success")) {
          if (!response?.success) {
            dispatch(failure());
            dispatch(alertActions.error(response?.message || "Something went wrong..."));
          } else {
            dispatch(success());
            dispatch(alertActions.success(response?.message || "Successfully charged"));
          }
        } else {
          dispatch(success());
          dispatch(alertActions.success("Charge request sended"));
        }
        dispatch(getOrder(orderId));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHARGE_SALES_TAX_PERMIT_STATE_FEE_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHARGE_SALES_TAX_PERMIT_STATE_FEE_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHARGE_SALES_TAX_PERMIT_STATE_FEE_FAILURE };
  }
}

function dissolveOrder(orderId) {
  return (dispatch) => {
    dispatch(request());

    customerService.dissolveOrder(orderId).then(
      (response) => {
        if (response?.success) {
          dispatch(success());
          dispatch(alertActions.success(response?.message || "Successfully dissolved"));
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response?.message || "Something went wrong..."));
        }
        dispatch(getOrder(orderId));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.DISSOLVE_ORDER_REQUEST };
  }
  function success() {
    return { type: customerConstants.DISSOLVE_ORDER_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.DISSOLVE_ORDER_FAILURE };
  }
}

function clearCustomerInfo(orderId, customerId) {
  return (dispatch) => {
    dispatch(request());

    customerService.clearCustomerInfo(customerId).then(
      (response) => {
        if (!response?.success) {
          dispatch(failure());
          dispatch(alertActions.error(response.message || "Something went wrong..."));
        } else {
          dispatch(success());
          dispatch(getOrder(orderId));
          dispatch(alertActions.success(response.message));
        }
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CLEAR_CUSTOMER_INFO_REQUEST };
  }
  function success() {
    return { type: customerConstants.CLEAR_CUSTOMER_INFO_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CLEAR_CUSTOMER_INFO_FAILURE };
  }
}

function createDelayedUpsell({ orderId, body, message }) {
  return (dispatch) => {
    dispatch(request());

    customerService.createDelayedUpsell({ orderId, body }).then(
      (order) => {
        if (order?.products[0]?.paymentStatus === "Declined") {
          dispatch(alertActions.error("Card was declined"));
        } else {
          dispatch(alertActions.success(message || "Upsell was charged successfully"));
        }
        dispatch(success());
        dispatch(getOrder(orderId));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(handleError(error)?.message || error?.message || error?.toString()));

        // dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CREATE_DELAYED_UPSELL_REQUEST };
  }
  function success() {
    return { type: customerConstants.CREATE_DELAYED_UPSELL_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CREATE_DELAYED_UPSELL_FAILURE };
  }
}

function unlockAutomationAction(orderId, productId) {
  return (dispatch) => {
    dispatch(request());

    customerService.unlockAutomation(orderId, productId).then(
      (response) => {
        if (!response.success) {
          dispatch(failure());
          dispatch(alertActions.error(response.message || "Something went wrong..."));
        } else {
          dispatch(success());
          dispatch(alertActions.success(response.message || "Order added to queue for processing"));
        }

        dispatch(getOrder(orderId));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.UNLOCK_AUTOMATION_REQUEST };
  }
  function success() {
    return { type: customerConstants.UNLOCK_AUTOMATION_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.UNLOCK_AUTOMATION_FAILURE };
  }
}

function changeEmail(orderId, email, cb) {
  return (dispatch) => {
    request();

    customerService.changeEmail(orderId, email).then(
      () => {
        success();
        dispatch(alertActions.success("Email has been changed successfully."));
        if (cb) {
          cb();
        } else {
          dispatch(customerActions.getOrder(orderId));
        }
      },
      (error) => {
        failure();
        dispatch(alertActions.error(error?.message || error?.toString()));
      }
    );
  };

  function request() {
    return { type: customerConstants.CHANGE_EMAIL_REQUEST };
  }
  function success() {
    return { type: customerConstants.CHANGE_EMAIL_SUCCESS };
  }
  function failure() {
    return { type: customerConstants.CHANGE_EMAIL_FAILURE };
  }
}
