import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Button } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment-timezone";
import clsx from "clsx";

import { getBillsOfSelectedProduct, getSubscriptionStatus } from "_helpers";
import { customerActions } from "_actions";

import ConfirmationModal from "components/ConfirmationModal";

const StyledRefundedAmountContainer = styled.span`
  color: #fc727a;
`;

const Billing = ({ orderDetails, selectedProduct }) => {
  const dispatch = useDispatch();
  const { subscriptionDetails } = useSelector((state) => state.customers);
  const [showRetryConfirm, setShowRetryConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showCancelAndRefundConfirm, setShowCancelAndRefundConfirm] = useState(false);

  const productIdx = selectedProduct;

  const { id: orderId } = orderDetails.order || {};
  const {
    id: productId,
    code: { subscription },
    processingResult: { status, logOfStatuses },
  } = orderDetails.products[productIdx] || {};

  const cancelDate = useMemo(
    () => logOfStatuses.find(({ status }) => status === "Cancelled"),
    logOfStatuses
  )?.timestamp;
  const billsOfSelectedProduct = useMemo(
    () => getBillsOfSelectedProduct(subscriptionDetails, productId),
    [subscriptionDetails, productId]
  );

  const isProductBeRefunded = billsOfSelectedProduct?.filter(({ status }) => status === "Success")?.length < 1;
  const subscriptionStatus = useMemo(
    () => getSubscriptionStatus(orderDetails, productIdx, subscriptionDetails),
    [orderDetails, productIdx]
  );

  const handleRetryFailedPayment = useCallback(() => {
    dispatch(customerActions.rerunSubscription(orderId, productId));
  }, [dispatch, orderId, productId]);

  const handleCancel = useCallback(() => {
    dispatch(customerActions.unsubscribeProducts(orderId, [productId]));
  }, [dispatch, orderId, productId]);

  const handleCancelAndRefund = useCallback(() => {
    dispatch(customerActions.refundLastTransaction(orderId, productId));
  }, [dispatch, orderId, productId]);

  const statusClasses = clsx({
    ["text-success"]: subscriptionStatus === "Active",
    ["text-warning"]: subscriptionStatus === "Declined",
    ["text-danger"]: subscriptionStatus === "Cancelled",
  });

  return (
    <div className="form-border monitoring-wrapper">
      <br />

      <Row>
        <Col sm={6}>
          <span>Subscription Status:</span>
        </Col>
        <Col sm={6}>
          <strong className={statusClasses}>{subscriptionStatus}</strong>
        </Col>
      </Row>

      <br />

      <Row>
        <Col sm={6} className="d-flex align-items-center">
          <span>Manually Retry Failed Payment:</span>
        </Col>
        <Col sm={6}>
          <Button
            size="sm"
            variant="info"
            disabled={!(subscriptionStatus === "Declined")}
            onClick={() => setShowRetryConfirm(true)}
          >
            Retry Failed Payment
          </Button>
        </Col>
      </Row>

      <br />

      <Row>
        <Col sm={12} md={8} style={{ marginTop: 10 }}>
          <div className="form-border">
            <Table responsive>
              <thead>
                <tr>
                  <th>Billing History</th>
                  <th>Amount ($)</th>
                  <th>Date Billed</th>
                  <th>Last 4 CC</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {!!billsOfSelectedProduct.length &&
                  billsOfSelectedProduct.map(
                    ({ billNumber, transactionAmount, refundedAmount, dateBilled, last4, status }) => (
                      <tr key={`${billNumber}-${dateBilled}-${status}-${last4}`}>
                        <td>{`Subscription Bill #${billNumber}`}</td>
                        <td>
                          {transactionAmount}&nbsp;
                          <StyledRefundedAmountContainer>{refundedAmount || 0}</StyledRefundedAmountContainer>
                        </td>
                        <td>{moment.utc(dateBilled).format("YYYY/MM/DD")}</td>
                        <td>{last4}</td>
                        <td>{status}</td>
                      </tr>
                    )
                  )}

                {!billsOfSelectedProduct.length && (
                  <tr>
                    <td>No Bills</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col sm={12} md={4} style={{ marginTop: 10 }}>
          <div className="form-border">
            <h6>Cancelling and Refunding</h6>
            <Row>
              <Col sm={12} style={{ marginTop: 10 }}>
                <Button
                  style={{ width: "100%" }}
                  size="sm"
                  variant="outline-danger"
                  disabled={!subscription || status === "Cancelled"}
                  onClick={() => setShowCancelConfirm(true)}
                >
                  Cancel recurring moving forward
                </Button>
              </Col>
              <Col sm={12} style={{ marginTop: 10 }}>
                <Button
                  style={{ width: "100%" }}
                  size="sm"
                  variant="outline-danger"
                  disabled={!subscription || isProductBeRefunded}
                  onClick={() => setShowCancelAndRefundConfirm(true)}
                >
                  {status === "Cancelled" ? "Refund last payment" : "Cancel and Refund last payment"}
                </Button>
              </Col>
              <Col sm={12} style={{ marginTop: 10 }}>
                <Row>
                  <Col sm={12} className="d-flex justify-content-center">
                    <strong>Cancel Date:</strong>
                  </Col>
                  <Col sm={12} className="d-flex justify-content-center">
                    <span>{!cancelDate ? "N/A" : moment.utc(cancelDate).local().format("YYYY/MM/DD")}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <ConfirmationModal
        show={showRetryConfirm}
        handleClose={() => {
          setShowRetryConfirm(false);
        }}
        handleConfirm={() => {
          handleRetryFailedPayment();
          setShowRetryConfirm(false);
        }}
        body="Are you sure you want to retry failed payment?"
      />

      <ConfirmationModal
        show={showCancelConfirm}
        handleClose={() => {
          setShowCancelConfirm(false);
        }}
        handleConfirm={() => {
          handleCancel();
          setShowCancelConfirm(false);
        }}
        body="Are you sure you want to cancel Subscription?"
      />

      <ConfirmationModal
        show={showCancelAndRefundConfirm}
        handleClose={() => {
          setShowCancelAndRefundConfirm(false);
        }}
        handleConfirm={() => {
          handleCancelAndRefund();
          setShowCancelAndRefundConfirm(false);
        }}
        body="Are you sure you want to cancel and refund Subscription?"
      />
    </div>
  );
};

export default Billing;
