import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Form, Row, Col, Table } from "react-bootstrap";
import moment from "moment-timezone";

import { reportsProductsProcessingActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

const PRODUCT_PROCESSING_COLUMNS = [
  { key: "username", label: "Agent" },
  { key: "completed", label: "Completed" },
  { key: "error", label: "Errors" },
];

export default function EinProcessing() {
  const defaultCat = localStorage.getItem("@EINOption_Status/category") || "EIN";
  const dispatch = useDispatch();

  const availableCategories = useSelector(({ authentication: { user } }) => user.availableCategories);
  const productsProcessing = useSelector(
    ({ reportsProductsProcessing: { productsProcessing } }) => productsProcessing || []
  );
  const { loading } = useSelector(({ reportsProductsProcessing }) => reportsProductsProcessing);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateRange, setDateRange] = useState("today");
  const [category, setCategory] = useState(availableCategories.includes(defaultCat) ? defaultCat : "EIN");

  const productsProcessingReport = () => {
    dispatch(reportsProductsProcessingActions.getAll({ from: startDate, to: endDate, category }));
  };

  const handleChangeCategory = ({ target: { value } }) => {
    setCategory(value);
    localStorage.setItem("@EINOption_Status/category", value);
  };

  useEffect(() => {
    switch (dateRange) {
      case "today":
        setStartDate(moment().format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "yesterday":
        setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        break;

      case "weektodate":
        setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "lastweek":
        setStartDate(moment().day(1).subtract(7, "day").format("YYYY-MM-DD"));
        setEndDate(moment().day(7).subtract(7, "day").format("YYYY-MM-DD"));
        break;

      case "monthtodate":
        setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "pastmonth":
        setStartDate(moment().subtract(1, "months").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "lastmonth":
        setStartDate(moment().subtract(1, "months").date(1).format("YYYY-MM-DD"));
        setEndDate(moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD"));
        break;

      default:
        break;
    }
  }, [dateRange]);

  useEffect(() => {
    productsProcessingReport();
  }, []);

  const accountsReportRenderer = () => {
    if (!productsProcessing.length)
      return (
        <tr>
          <td>No Results</td>
        </tr>
      );
    return productsProcessing.map((row) => (
      <tr key={row.username}>
        {PRODUCT_PROCESSING_COLUMNS.map(({ key }, colIdx) => (
          <td key={row.username + colIdx}>{row[key]}</td>
        ))}
      </tr>
    ));
  };

  return (
    <>
      <Container fluid className="mt-5 py-4">
        <Card className="p-2">
          <Row>
            <Col>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md>
                    <Form.Label>Date Range:</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="dateRange"
                      value={dateRange}
                      onChange={({ target: { value } }) => setDateRange(value)}
                    >
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="weektodate">Week to Date</option>
                      <option value="lastweek">Last Week</option>
                      <option value="monthtodate">Month to Date</option>
                      <option value="pastmonth">Past Month</option>
                      <option value="lastmonth">Last Month</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="startDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={startDate}
                      onChange={({ target: { value } }) => setStartDate(value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="endDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={endDate}
                      onChange={({ target: { value } }) => setEndDate(value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md>
                    <Form.Label>Category</Form.Label>
                    <Form.Control size="sm" as="select" value={category} onChange={handleChangeCategory}>
                      {availableCategories.map((cat) => (
                        <option key={cat} value={cat}>
                          {cat}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>

            <Col md={2} style={{ display: "flex", alignItems: "center" }}>
              <Button size="sm" className="mr-2" isLoading={loading} onClick={productsProcessingReport}>
                Filter
              </Button>
            </Col>
          </Row>
        </Card>

        <Card>
          <Table responsive className="orders-table">
            <thead>
              <tr>
                {PRODUCT_PROCESSING_COLUMNS.map(({ key, label }) => (
                  <th key={key}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>{!loading && accountsReportRenderer()}</tbody>
          </Table>

          {!!loading && <Spinner />}
        </Card>
      </Container>
    </>
  );
}
