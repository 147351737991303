import React, { useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Spinner from "components/Spinner";

import { CATEGORIES_ARRAY } from "variables/Variables";

import IncAmendmentBusinessAddressChangeForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentBusinessAddressChangeForm";
import IncAmendmentTrademarkIntensiveForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentTrademarkIntensiveForm";
import IncAmendmentTrademarkMinorForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentTrademarkMinorForm";
import IncAmendmentForeignBusinessNameChangeForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentForeignBusinessNameChangeForm";
import IncAmendmentBusinessNameChangeForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentBusinessNameChangeForm";
import IncAmendmentRegisteredAgentChangeForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentRegisteredAgentChangeForm";
import IncAmendmentOfficeChangeForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentOfficeChangeForm";
import IncAmendmentManagerChangeForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentManagerChangeForm";
import IncAmendmentAllOtherAmendmentsForm from "components/OrderDetails/CreateOrder/IncForms/IncAmendmentAllOtherAmendmentsForm";
import IncBoiForm from "components/OrderDetails/CreateOrder/IncForms/IncBoiForm";
import IncEinForm from "components/OrderDetails/CreateOrder/IncForms/IncEinForm";
import IncOperatingAgreement from "components/OrderDetails/CreateOrder/IncForms/IncOperatingAgreement";
import IncCertificateOfGoodStanding from "components/OrderDetails/CreateOrder/IncForms/IncCertificateOfGoodStanding";
import IncCertifiedCopy from "components/OrderDetails/CreateOrder/IncForms/IncCertifiedCopy";
import IncCertifiedCopyStateFee from "components/OrderDetails/CreateOrder/IncForms/IncCertifiedCopyStateFee";

// import TrademarkCertifiedCopy from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkCertifiedCopy";
// import TrademarkExtension from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkExtension";
// import TrademarkKeepTrademarkAlive from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkKeepTrademarkAlive";
// import TrademarkMonitoring from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkMonitoring";
// import TrademarkOfficeActionResponse from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkOfficeActionResponse";
// import TrademarkRegister from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkRegister";
// import TrademarkRenewal from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkRenewal";
// import TrademarkRevivalFromAbandonment from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkRevivalFromAbandonment";
// import TrademarkRushProcessing from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkRushProcessing";
// import TrademarkSearchBasic from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkSearchBasic";
// import TrademarkSearchCommonLaw from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkSearchCommonLaw";
// import TrademarkSearchFederalState from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkSearchFederalState";
// import TrademarkSearchGlobal from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkSearchGlobal";
// import TrademarkStatementOfUse from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkStatementOfUse";
// import TrademarkUsptoFillingFee from "components/OrderDetails/CreateOrder/TrademarkForms/TrademarkUsptoFillingFee";

const availableProductCodes = [
  // INC
  "INC_EIN",
  "INC_BOI",
  "INC_Amendment_BusinessAddressChange",
  "INC_Amendment_ManagerChange",
  "INC_Amendment_OfficeChange",
  "INC_Amendment_RegisteredAgentChange",
  "INC_Amendment_BusinessNameChange",
  "INC_Amendment_ForeignBusinessNameChange",
  "INC_Amendment_AllOtherAmendments",
  "INC_Amendment_TrademarkMinor",
  "INC_Amendment_TrademarkIntensive",
  "INC_OperatingAgreement",
  "INC_CertificateOfGoodStanding",
  "INC_CertifiedCopy",
  "INC_CertifiedCopyStateFee",

  // INC_States
  "INC_States_EIN",
  "INC_States_BOI",
  "INC_States_Amendment_BusinessAddressChange",
  "INC_States_Amendment_ManagerChange",
  "INC_States_Amendment_OfficeChange",
  "INC_States_Amendment_RegisteredAgentChange",
  "INC_States_Amendment_BusinessNameChange",
  "INC_States_Amendment_ForeignBusinessNameChange",
  "INC_States_Amendment_AllOtherAmendments",
  "INC_States_Amendment_TrademarkMinor",
  "INC_States_Amendment_TrademarkIntensive",
  "INC_States_OperatingAgreement",
  "INC_States_CertificateOfGoodStanding",
  "INC_States_CertifiedCopy",
  "INC_States_CertifiedCopyStateFee",

  // Trademarks
  // "TRADEMARK_RegisterTrademark",
];

const ErrorsContainer = styled.div`
  width: 100%;

  & p {
    font-weight: bold;
    width: 100%;
    word-wrap: break-word;
    font-size: 0.875rem;
  }
  & p span {
    display: inline-block;
    margin-left: 8px;
    margin-top: 8px;
    padding: 2px;
    font-weight: normal;
    color: firebrick;
    border-radius: 4px;
    background-color: #fdc9c9;
  }
`;

const AddProductForm = (props) => {
  const {
    isEditing,
    productDetails,
    validated,
    errors,
    setValidated,
    setErrors,
    handleAddProduct,
    handleChangeProduct,
    handleChangeProductData,
    handleCancelEditing,
    handleResetProduct,
    handleUpdateProduct,
  } = props;

  const formRef = useRef(null);

  const loadingVariants = useSelector(({ variants: { loading } }) => loading);
  const loadingOrderData = useSelector(({ customers: { loading } }) => loading);
  const { allProcessingOptions, allProductCodes } = useSelector(({ variants }) => variants);

  const loading = loadingVariants || loadingOrderData;

  const defaultShowCodes = localStorage.getItem("@CreateOrder/productType_showCodes") === "true" ? true : false;

  const [showCodes, setShowCodes] = useState(defaultShowCodes);
  // const [validated, setValidated] = useState(false);
  // const [errors, setErrors] = useState([]);
  // const [category, setCategory] = useState("");

  const processingOptionsByCategory = allProcessingOptions[productDetails?.category] || [];
  const productCodesByCategory = allProductCodes[productDetails?.category] || [];

  const handleShowCodes = (e) => {
    // e.stopPropagation();

    const checked = !showCodes;

    setShowCodes(checked);

    localStorage.setItem("@CreateOrder/productType_showCodes", checked);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setErrors([]);

    if (formRef.current.checkValidity() === false) {
      // if (e) e.stopPropagation();
      setValidated(true);
    } else {
      if (isEditing) {
        handleUpdateProduct();
      } else {
        handleAddProduct();
      }

      setValidated(false);
      setErrors([]);
    }
  };

  const handleError = (error) => {
    setErrors((prev) => [...prev, error]);
  };

  const handleChangeAndValidate = (newObj) => {
    handleChangeProductData(newObj);
    setErrors([]);
  };

  const handleChangeCategory = ({ target: { value } }) => {
    if (!isEditing) {
      handleResetProduct(value);
    }
  };

  const formProps = {
    productDetails: productDetails?.data,
    onChange: handleChangeAndValidate,
    onError: handleError,
  };

  const renderFields = (code) => {
    switch (code) {
      // case "TRADEMARK_RegisterTrademark":
      //   return <TrademarkRegister {...formProps} />;
      // case "TRADEMARK_USPTOFilingFee":
      //   return <TrademarkUsptoFillingFee />;
      // case "TRADEMARK_Trademark_Search_Federal_State":
      //   return <TrademarkSearchFederalState />;
      // case "TRADEMARK_Trademark_Search_Common_Law":
      //   return <TrademarkSearchCommonLaw />;
      // case "TRADEMARK_Trademark_Search_Basic":
      //   return <TrademarkSearchBasic />;
      // case "TRADEMARK_Trademark_Search_Global":
      //   return <TrademarkSearchGlobal />;
      // case "TRADEMARK_Trademark_Monitoring":
      //   return <TrademarkMonitoring />;
      // case "TRADEMARK_Trademark_Office_Action_Response":
      //   return <TrademarkOfficeActionResponse />;
      // case "TRADEMARK_Trademark_Statement_Of_Use":
      //   return <TrademarkStatementOfUse />;
      // case "TRADEMARK_Trademark_Renewal":
      //   return <TrademarkRenewal />;
      // case "TRADEMARK_Trademark_Rush_Processing":
      //   return <TrademarkRushProcessing />;
      // case "TRADEMARK_Trademark_Extension":
      //   return <TrademarkExtension />;
      // case "TRADEMARK_Trademark_Revival_From_Abandonment":
      //   return <TrademarkRevivalFromAbandonment />;
      // case "TRADEMARK_Trademark_Keep_Trademark_Alive":
      //   return <TrademarkKeepTrademarkAlive />;
      // case "TRADEMARK_Trademark_Certified_Copy":
      //   return <TrademarkCertifiedCopy />;

      case "INC_States_EIN":
      case "INC_EIN":
        return <IncEinForm {...formProps} />;
      case "INC_States_BOI":
      case "INC_BOI":
        return <IncBoiForm {...formProps} />;
      case "INC_States_Amendment_BusinessAddressChange":
      case "INC_Amendment_BusinessAddressChange":
        return <IncAmendmentBusinessAddressChangeForm {...formProps} />;
      case "INC_States_Amendment_ManagerChange":
      case "INC_Amendment_ManagerChange":
        return <IncAmendmentManagerChangeForm {...formProps} />;
      case "INC_States_Amendment_OfficeChange":
      case "INC_Amendment_OfficeChange":
        return <IncAmendmentOfficeChangeForm {...formProps} />;
      case "INC_States_Amendment_RegisteredAgentChange":
      case "INC_Amendment_RegisteredAgentChange":
        return <IncAmendmentRegisteredAgentChangeForm {...formProps} />;
      case "INC_States_Amendment_BusinessNameChange":
      case "INC_Amendment_BusinessNameChange":
        return <IncAmendmentBusinessNameChangeForm {...formProps} />;
      case "INC_States_Amendment_ForeignBusinessNameChange":
      case "INC_Amendment_ForeignBusinessNameChange":
        return <IncAmendmentForeignBusinessNameChangeForm {...formProps} />;
      case "INC_States_Amendment_AllOtherAmendments":
      case "INC_Amendment_AllOtherAmendments":
        return <IncAmendmentAllOtherAmendmentsForm {...formProps} />;
      case "INC_States_Amendment_TrademarkMinor":
      case "INC_Amendment_TrademarkMinor":
        return <IncAmendmentTrademarkMinorForm {...formProps} />;
      case "INC_States_Amendment_TrademarkIntensive":
      case "INC_Amendment_TrademarkIntensive":
        return <IncAmendmentTrademarkIntensiveForm {...formProps} />;

      case "INC_States_OperatingAgreement":
      case "INC_OperatingAgreement":
        return <IncOperatingAgreement {...formProps} />;

      case "INC_States_CertificateOfGoodStanding":
      case "INC_CertificateOfGoodStanding":
        return <IncCertificateOfGoodStanding {...formProps} />;

      case "INC_States_CertifiedCopy":
      case "INC_CertifiedCopy":
        return <IncCertifiedCopy {...formProps} />;

      case "INC_States_CertifiedCopyStateFee":
      case "INC_CertifiedCopyStateFee":
        return <IncCertifiedCopyStateFee {...formProps} />;

      default:
        return null;
    }
  };

  return !loading ? (
    <Form validated={validated} noValidate ref={formRef} onSubmit={handleSubmit}>
      <>
        {isEditing ? <h3>Edit Product</h3> : <h3>Add New Product</h3>}

        <div className="d-flex justify-content-end" style={{ color: "gray" }}>
          <Form.Check id="show-codes-checkbox" type="checkbox" checked={showCodes} onChange={handleShowCodes} />
          <Form.Label htmlFor="show-codes-checkbox">Show Codes</Form.Label>
        </div>

        <Form.Group controlId="option">
          <Form.Label>Category</Form.Label>
          <Form.Control
            required
            readOnly={isEditing}
            disabled={isEditing}
            as="select"
            size="sm"
            name="category"
            value={productDetails?.category || ""}
            onChange={handleChangeCategory}
          >
            <option value="">Select Category...</option>
            {CATEGORIES_ARRAY.map((item, idx) => (
              <option key={`category-option-${item}-${idx}`} value={item.code}>
                {showCodes ? item.code : item.value}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {productDetails?.category && !loading && (
          <>
            <Form.Group controlId="option">
              <Form.Label>Product Processing Option</Form.Label>
              <Form.Control
                required
                as="select"
                size="sm"
                name="processingOption"
                value={productDetails.processingOption || ""}
                onChange={handleChangeProduct}
                onInvalid={() => handleError("Product Processing Option")}
              >
                <option value="">Select a processing option...</option>
                {processingOptionsByCategory?.map((key, idx) => (
                  <option key={`${key.code}-${idx}`} value={key.code}>
                    {showCodes ? key.code : key.value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Product Type
              </Form.Label>
              <Form.Control
                required
                readOnly={isEditing}
                disabled={isEditing}
                as="select"
                size="sm"
                name="type"
                value={productDetails.type || ""}
                onChange={handleChangeProduct}
              >
                <option value="">Select a product type...</option>
                {productCodesByCategory
                  ?.filter(
                    (item) => item.category === productDetails?.category && availableProductCodes.includes(item.code)
                  )
                  .map((item) => (
                    <option key={item.code} value={item.code}>
                      {showCodes ? item.code : item.customerName}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            {productDetails.type && <></>}
          </>
        )}
      </>

      {productDetails?.type && (
        <>
          <label>Please, provide necessary product details</label>
          {renderFields(productDetails?.type)}
          <ErrorsContainer>
            {errors.length > 0 && (
              <p>
                Please, check errors in these fields:
                <br />
                {errors.map((error, idx) => (
                  <span key={`error-${idx}-${error}`}>{error}</span>
                ))}
              </p>
            )}
          </ErrorsContainer>
        </>
      )}

      {isEditing ? (
        <div>
          <Button variant="success" type="submit" className="btn-sm pull-right ml-1">
            Update
          </Button>
          <Button variant="secondary" type="button" className="btn-sm pull-right" onClick={handleCancelEditing}>
            Cancel
          </Button>
        </div>
      ) : (
        productDetails &&
        productDetails.type && (
          <>
            <Button
              variant="secondary"
              type="button"
              className="btn-sm pull-left"
              onClick={() => {
                setValidated(false);
                handleResetProduct();
              }}
            >
              Reset&nbsp;
              <i className="fa fa-trash" />
            </Button>
            <Button variant="success" type="submit" className="btn-sm pull-right">
              Add product&nbsp;
              <i className="fa fa-arrow-right" />
            </Button>
          </>
        )
      )}
    </Form>
  ) : (
    <Spinner />
  );
};

export default AddProductForm;
