import { Form, Row, Col } from "react-bootstrap";
import moment from "moment-timezone";

import { month_array } from "variables/Variables";

export default function DateOfBirth({ productDetails, onChange, renderExtraLabel }) {
  function datechange({ target: { name, value } }) {
    const oldDate = moment(productDetails?.dateOfBirth || new Date())
      .format("YYYY-MM-DD")
      .split("-");

    const newVal = value < 10 ? "0" + value : value;

    if (name === "year") {
      oldDate[0] = newVal;
    }
    if (name === "month") {
      oldDate[1] = newVal;
      oldDate[2] = "01";
    }
    if (name === "date") {
      oldDate[2] = newVal;
    }

    const leapYear = (oldDate[0] % 4 === 0 && oldDate[0] % 100 !== 0) || oldDate[0] % 400 === 0;

    if (!leapYear && oldDate[1] === "02" && oldDate[2] === "29") oldDate[2] = "28";

    const newDate = oldDate.join("-");

    onChange({ target: { name: "dateOfBirth", value: moment(newDate).format("YYYY-MM-DD") } });
  }

  const yearRange = [];
  const dateRange = [];
  const thisYear = new Date().getFullYear();

  const d = new Date(moment(productDetails?.dateOfBirth || new Date()).format("YYYY-MM-DD"));
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const date = d.getDate();
  const leapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  let date_array = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 2 && leapYear) {
    date_array[1] = 29;
  }

  for (let i = 1; i <= date_array[d.getMonth()]; i++) {
    dateRange.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = thisYear, key = 0; i >= 1920; i--, key++) {
    yearRange.push(
      <option key={key} value={i}>
        {i}
      </option>
    );
  }

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={5}>
        Date of Birth: {renderExtraLabel && renderExtraLabel()}
      </Form.Label>
      <Col sm={7} className="d-flex align-items-center">
        <Form.Control as="select" name="month" value={productDetails?.dateOfBirth ? month : ""} onChange={datechange}>
          <option value="">Month</option>
          {Object.keys(month_array).map((prop, key) => (
            <option key={key} value={prop}>
              {month_array[prop]}
            </option>
          ))}
        </Form.Control>
        <Form.Control as="select" name="date" value={productDetails?.dateOfBirth ? date : ""} onChange={datechange}>
          <option value="">Date</option>
          {dateRange}
        </Form.Control>
        <Form.Control as="select" name="year" value={productDetails?.dateOfBirth ? year : ""} onChange={datechange}>
          <option value="">Year</option>
          {yearRange}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}
