import moment from "moment";
import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { phoneNumRegexp, phoneCleaner, handleKey, checkIsIncEin, checkIsIncLLCIncorporation } from "_helpers";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import ActivityIncEdit from "components/OrderDetails/ProductDetails/Incorporation/Activity/Edit";
import DateEdit from "components/OrderDetails/ProductDetails/Incorporation/Date/Edit";

import StatusEdit from "../../Status/Edit";

import { state_array, designatorsLLC, EIN_title_array, suffixArray, TAB_CODES } from "variables/Variables";

function FormLLCInc({ orderDetails, selectedProduct, onError, onChange, ...props }) {
  const [membersCollapsed, setMembersCollapsed] = useState(true);
  const [newMember, setNewMember] = useState("");

  const productDetails = orderDetails?.products[selectedProduct];
  const llcEINProductIndex = orderDetails?.products?.findIndex((p) => checkIsIncEin(p.code.code));
  const hasLlcEINProduct = Boolean(llcEINProductIndex >= 0);

  const einProductIndex = orderDetails?.products?.findIndex((p) => p.code.code === "EIN_LLC");
  const hasEinProduct = Boolean(einProductIndex >= 0);

  const isIncLLC = checkIsIncLLCIncorporation(productDetails?.code?.code);

  const getManagedBy = () => {
    if (!productDetails?.managedByManagers && !productDetails?.managedByMembers) return "";
    if (productDetails?.managedByManagers && productDetails?.managedByMembers) return "Members & Managers";
    return productDetails?.managedByMembers ? "Members" : "Managers";
  };

  const handleAddError = (field) => onError(TAB_CODES.llcIncInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value === "" ? null : value } : product
      ),
    };

    if (name === "activityPrimaryActivity") {
      newObj.products[selectedProduct].activitySpecificProducts = null;
      newObj.products[selectedProduct].productDescription = null;
      newObj.products[selectedProduct].productExtraQuestion1 = null;
      newObj.products[selectedProduct].productExtraQuestion2 = null;
      newObj.products[selectedProduct].productExtraQuestion3 = null;
    }

    if (name === "activitySpecificProducts") {
      newObj.products[selectedProduct].productDescription = null;
      newObj.products[selectedProduct].productExtraQuestion1 = null;
      newObj.products[selectedProduct].productExtraQuestion2 = null;
      newObj.products[selectedProduct].productExtraQuestion3 = null;
    }

    if (name === "companyName" && hasLlcEINProduct) {
      newObj.products[llcEINProductIndex].legalName = value;
    }

    onChange(newObj);
  };

  const checkCanUnset = () => {
    if (productDetails?.code?.code === "EIN_LLCIncorporation") {
      if (hasEinProduct) {
        const einProduct = orderDetails?.products[einProductIndex];
        if (
          productDetails?.processingResult?.status === "Completed" &&
          !productDetails?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          return false;
        }

        if (
          einProduct?.processingResult?.status === "Completed" &&
          !einProduct?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          return false;
        }

        return true;
      } else {
        if (
          productDetails?.processingResult?.status === "Completed" &&
          !productDetails?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          return false;
        }

        return true;
      }
    }

    if (checkIsIncLLCIncorporation(productDetails?.code?.code)) {
      if (
        productDetails?.processingResult?.status === "Completed" &&
        !productDetails?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
      ) {
        return false;
      }

      return true;
    }
  };

  // const handleProductOwnerChange = ({ target: { name, value } }) =>
  //   handleProductChange({
  //     target: {
  //       name: "owner",
  //       value: { ...productDetails.owner, [name]: name === "suffixName" && value === "" ? null : value },
  //     },
  //   });

  const handleBoiWasDeclinedByCustomerChange = ({ target: { value } }) => {
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, boiWasDeclinedByCustomer: value === "Yes" } : product
      ),
    });
  };

  const handleManagedBy = ({ target: { value } }) => {
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct
          ? { ...product, managedByMembers: value === "Members", managedByManagers: value === "Managers" }
          : product
      ),
    });
  };

  const handleMemberDelete = (id) => {
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (hasLlcEINProduct && llcEINProductIndex === idx) {
          return { ...product, numberOfMembers: product.numberOfMembers - 1 };
        }

        if (idx === selectedProduct) {
          return { ...product, members: [...product.members.filter((_, idx) => idx !== id)] };
        }

        return product;
      }),
    });
  };
  const handleMemberAdd = (newMemberObj) => {
    const { name } = newMemberObj;
    const newObj = {
      name: name.trim(),
    };

    if (newObj.name) {
      onChange({
        ...orderDetails,
        products: orderDetails.products.map((product, idx) => {
          if (hasLlcEINProduct && llcEINProductIndex === idx) {
            return { ...product, numberOfMembers: product.numberOfMembers + 1 };
          }

          if (idx === selectedProduct) {
            return { ...product, members: [...product.members, newObj] };
          }

          return product;
        }),
      });
    }
  };

  const handleNewMemberKeyPressed = (e) => {
    if (handleKey(e)) {
      e.preventDefault();
      if (newMember) {
        handleMemberAdd({ name: newMember });
        setNewMember("");
      }
    }
  };

  const handlePrincipalOfficerChange = ({ target: { name, value } }) => {
    let newData = value;

    if (name === "phone") {
      newData = phoneCleaner(newData);
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct
          ? {
              ...product,
              principalOfficer: {
                ...product.principalOfficer,
                [name]: newData,
              },
            }
          : product
      ),
    });
  };

  const handleCorporateAddressChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct
          ? {
              ...product,
              corporateAddress: {
                ...product.corporateAddress,
                [name]: value,
              },
            }
          : product
      ),
    });

  const handleMailingAddressChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct
          ? {
              ...product,
              mailingAddress: {
                ...product.mailingAddress,
                [name]: value,
              },
            }
          : product
      ),
    });

  const handleIncorporatedDate = (value) => {
    handleProductChange({ target: { name: "incorporatedDate", value } });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LLC Inc Corporation</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />

      <DateEdit
        label="Incorporated Date:"
        date={productDetails?.incorporatedDate}
        isCanUnset={checkCanUnset()}
        onChange={handleIncorporatedDate}
      />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Limited Liability Company Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="companyName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails.companyName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Company Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Alternate Company Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="altCompanyName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails.altCompanyName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Alternate Company Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Designator:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            required={isIncLLC}
            as="select"
            name="designator"
            value={productDetails?.designator || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Designator")}
          >
            <option value="">Select a state</option>
            {designatorsLLC.map((value, idx) => (
              <option key={`${idx}-${value}`} value={value}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organized State:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={isIncLLC}
            size="sm"
            as="select"
            name="organizedState"
            value={productDetails?.organizedState || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Organized State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Was BOI offer declined by customer?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="boiWasDeclinedByCustomer_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="boiWasDeclinedByCustomer"
            value="Yes"
            defaultChecked={productDetails.boiWasDeclinedByCustomer}
            onChange={handleBoiWasDeclinedByCustomerChange}
          />
          <Form.Check
            id="boiWasDeclinedByCustomer_r_no"
            type="radio"
            label="No"
            name="boiWasDeclinedByCustomer"
            value="No"
            defaultChecked={!productDetails.boiWasDeclinedByCustomer}
            onChange={handleBoiWasDeclinedByCustomerChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Managed By:
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="managedBy_r_members"
            className="mr-3"
            type="radio"
            label="Members"
            name="managedBy"
            value="Members"
            defaultChecked={productDetails.managedByMembers && !productDetails.managedByManagers}
            onChange={handleManagedBy}
          />
          <Form.Check
            id="managedBy_r_managers"
            type="radio"
            label="Managers"
            name="managedBy"
            value="Managers"
            defaultChecked={productDetails.managedByManagers && !productDetails.managedByMembers}
            onChange={handleManagedBy}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5} style={{ cursor: "pointer" }} onClick={() => setMembersCollapsed(!membersCollapsed)}>
          <i className={`fa fa-caret-${membersCollapsed ? "down" : "up"}`} />
          {!getManagedBy() ? "Members" : getManagedBy()}:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.members?.length}
        </Form.Label>

        {!membersCollapsed && (
          <>
            <Col sm={12}>
              {productDetails.members.map((member, idx) => (
                <div
                  key={`${idx}-${member.name}`}
                  className="d-flex mb-2 p-1"
                  style={{ border: "1px solid gray", backgroundColor: "#fafafa", borderRadius: "8px" }}
                >
                  <div style={{ flex: "1", color: "gray" }}>#{idx + 1}</div>
                  <div style={{ flex: "9" }}>{member.name}</div>
                  <div style={{ flex: "2" }}>
                    <Button
                      size="sm"
                      type="button"
                      variant="danger"
                      className="ml-2 py-0 pull-right"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMemberDelete(idx);
                      }}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                  </div>
                </div>
              ))}
            </Col>
            <Form.Label column className="d-flex">
              <Form.Control
                size="sm"
                value={newMember}
                onChange={({ target: { value } }) => {
                  setNewMember(value);
                }}
                onKeyPress={handleNewMemberKeyPressed}
              />
              <Button
                size="sm"
                type="button"
                variant="success"
                className="ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleMemberAdd({ name: newMember });
                  setNewMember("");
                }}
              >
                Add
              </Button>
            </Form.Label>
          </>
        )}
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>
      <ActivityIncEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <hr />
      <h6 className="font-weight-bold">General questions</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is this your first LLC?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsFirstLLC_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="questionsFirstLLC"
            value={true}
            defaultChecked={productDetails.questionsFirstLLC}
            onChange={handleProductChange}
          />
          <Form.Check
            id="questionsFirstLLC_r_no"
            type="radio"
            label="No"
            name="questionsFirstLLC"
            value={false}
            defaultChecked={!productDetails.questionsFirstLLC}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you have employees?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsHaveEmployee_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="questionsHaveEmployee"
            value={true}
            defaultChecked={productDetails.questionsHaveEmployee}
            onChange={handleProductChange}
          />
          <Form.Check
            id="questionsHaveEmployee_r_no"
            type="radio"
            label="No"
            name="questionsHaveEmployee"
            value={false}
            defaultChecked={!productDetails.questionsHaveEmployee}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Have you started doing business?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsStartedDoingBusiness_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="questionsStartedDoingBusiness"
            value={true}
            defaultChecked={productDetails.questionsStartedDoingBusiness}
            onChange={handleProductChange}
          />
          <Form.Check
            id="questionsStartedDoingBusiness_r_no"
            type="radio"
            label="No"
            name="questionsStartedDoingBusiness"
            value={false}
            defaultChecked={!productDetails.questionsStartedDoingBusiness}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you accept Credit Card Payments?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="acceptCreditCardPayments_r_yes"
            className="mr-3"
            type="radio"
            label="Yes"
            name="acceptCreditCardPayments"
            value={true}
            defaultChecked={productDetails.acceptCreditCardPayments}
            onChange={handleProductChange}
          />
          <Form.Check
            id="acceptCreditCardPayments_r_no"
            type="radio"
            label="No"
            name="acceptCreditCardPayments"
            value={false}
            defaultChecked={!productDetails.acceptCreditCardPayments}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Primary Officer</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="firstName"
            value={productDetails.principalOfficer?.firstName || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="middleName"
            value={productDetails.principalOfficer?.middleName || ""}
            onChange={handlePrincipalOfficerChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="lastName"
            value={productDetails.principalOfficer?.lastName || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            name="suffixName"
            value={productDetails?.principalOfficer?.suffixName || ""}
            onChange={handlePrincipalOfficerChange}
          >
            <option value="">Please select</option>
            {suffixArray.map((prop, key) => (
              <option key={key} value={prop}>
                {prop}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className={clsx("form-control", "form-control-sm", {
              "is-invalid":
                productDetails?.principalOfficer?.phone &&
                !phoneNumRegexp.test(productDetails?.principalOfficer?.phone),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="phone"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={productDetails?.principalOfficer?.phone || ""}
            onChange={handlePrincipalOfficerChange}
            onInvalid={() => handleAddError("Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          E-mail:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="email"
            value={productDetails.principalOfficer?.email || ""}
            onChange={handlePrincipalOfficerChange}
          />
        </Col>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Corporate Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="address1"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            isInvalid={!/^(?!\s*$)[ a-zA-Z0-9\\/-]+$/i.test(productDetails.corporateAddress?.address1?.trim() || "")}
            value={productDetails.corporateAddress?.address1 || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            isInvalid={
              productDetails.corporateAddress?.address2
                ? !/^(?!\s*$)[ a-zA-Z0-9\\/-]+$/i.test(productDetails.corporateAddress?.address2?.trim() || "")
                : false
            }
            value={productDetails.corporateAddress?.address2 || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="city"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            isInvalid={!/^(?!\s*$)[ a-zA-Z/-]+$/i.test(productDetails.corporateAddress?.city || "")}
            value={productDetails.corporateAddress?.city || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            required
            as="select"
            name="state"
            isInvalid={!productDetails.corporateAddress?.state}
            value={productDetails.corporateAddress?.state || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="zip"
            pattern="^\d{5}$"
            maxLength={5}
            isInvalid={!/\d{5}$/i.test(productDetails.corporateAddress?.zip || "")}
            value={productDetails.corporateAddress?.zip || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            isInvalid={
              productDetails.corporateAddress?.county
                ? !/^(?!\s*$)[ a-zA-Z/-]+$/i.test(productDetails.corporateAddress?.county || "")
                : false
            }
            value={productDetails.corporateAddress?.county || ""}
            onChange={handleCorporateAddressChange}
            onInvalid={() => handleAddError("Corporate County")}
          />
        </Col>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Mailing Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={
              productDetails.mailingAddress?.zip ||
              productDetails.mailingAddress?.state ||
              productDetails.mailingAddress?.city
            }
            size="sm"
            type="text"
            name="address1"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            isInvalid={
              productDetails.mailingAddress?.address1
                ? !/^(?!\s*$)[ a-zA-Z0-9\\/-]+$/i.test(productDetails.mailingAddress?.address1?.trim() || "")
                : productDetails.mailingAddress?.zip ||
                  productDetails.mailingAddress?.state ||
                  productDetails.mailingAddress?.city
                ? true
                : false
            }
            value={productDetails.mailingAddress?.address1 || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z0-9\\\/\-]+$"
            maxLength={30}
            isInvalid={
              productDetails.mailingAddress?.address2
                ? !/^(?!\s*$)[ a-zA-Z0-9\\/-]+$/i.test(productDetails.mailingAddress?.address2?.trim() || "")
                : false
            }
            value={productDetails.mailingAddress?.address2 || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={
              productDetails.mailingAddress?.address1 ||
              productDetails.mailingAddress?.state ||
              productDetails.mailingAddress?.zip
            }
            size="sm"
            type="text"
            name="city"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            isInvalid={
              productDetails.mailingAddress?.city
                ? !/^(?!\s*$)[ a-zA-Z/-]+$/i.test(productDetails.mailingAddress?.city || "")
                : productDetails.mailingAddress?.address1 ||
                  productDetails.mailingAddress?.state ||
                  productDetails.mailingAddress?.zip
                ? true
                : false
            }
            value={productDetails.mailingAddress?.city || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={
              productDetails.mailingAddress?.address1 ||
              productDetails.mailingAddress?.zip ||
              productDetails.mailingAddress?.city
            }
            size="sm"
            as="select"
            name="state"
            isInvalid={
              (productDetails.mailingAddress?.address1 ||
                productDetails.mailingAddress?.zip ||
                productDetails.mailingAddress?.city) &&
              !productDetails.mailingAddress?.state
            }
            value={productDetails.mailingAddress?.state || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required={
              productDetails.mailingAddress?.address1 ||
              productDetails.mailingAddress?.state ||
              productDetails.mailingAddress?.city
            }
            size="sm"
            type="text"
            name="zip"
            pattern="^\d{5}$"
            maxLength={5}
            isInvalid={
              productDetails.mailingAddress?.zip
                ? !/\d{5}$/i.test(productDetails.mailingAddress?.zip || "")
                : productDetails.mailingAddress?.address1 ||
                  productDetails.mailingAddress?.state ||
                  productDetails.mailingAddress?.city
                ? true
                : false
            }
            value={productDetails.mailingAddress?.zip || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            pattern="^(?!\s*$)[ a-zA-Z\/\-]+$"
            maxLength={30}
            isInvalid={
              productDetails.mailingAddress?.county
                ? !/^(?!\s*$)[ a-zA-Z/-]+$/i.test(productDetails.mailingAddress?.county || "")
                : false
            }
            value={productDetails.mailingAddress?.county || ""}
            onChange={handleMailingAddressChange}
            onInvalid={() => handleAddError("Mailing County")}
          />
        </Col>
      </Form.Group>
    </div>
  );
}

export default FormLLCInc;
