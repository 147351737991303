import { Form, Row } from "react-bootstrap";
import moment from "moment-timezone";

export default function DateOfBirth({ productDetails }) {
  return (
    <Form.Group as={Row}>
      <Form.Label column sm="5">
        Date of Birth:
      </Form.Label>
      <Form.Label column sm="7">
        {productDetails?.dateOfBirth
          ? moment(moment(productDetails?.dateOfBirth, "YYYY-MM-DD")).format("MM/DD/YYYY")
          : "N/A"}
      </Form.Label>
    </Form.Group>
  );
}
