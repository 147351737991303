import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import { state_array } from "variables/Variables";

export const initAddress = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
};

export default function IndividualAddress({ address, onError, onChange }) {
  const handleChangeIndividualAddress = ({ target: { name, value } }) => onChange({ ...address, [name]: value });

  return (
    <>
      <br />
      <Form.Group as={Row}>
        <Form.Label column sm={12}>
          Address
        </Form.Label>
      </Form.Group>
      <div className="pl-3">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Address1:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="address1"
              // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={address?.address1 || ""}
              onChange={handleChangeIndividualAddress}
              onInvalid={() => onError("Address")}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Address2:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              size="sm"
              name="address2"
              type="text"
              // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={address?.address2 || ""}
              onChange={handleChangeIndividualAddress}
              onInvalid={() => onError("Address2")}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            City:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="city"
              // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={address?.city || ""}
              onChange={handleChangeIndividualAddress}
              onInvalid={() => onError("City")}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            State:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              as="select"
              size="sm"
              name="state"
              value={address?.state || ""}
              onChange={handleChangeIndividualAddress}
              onInvalid={() => onError("State")}
            >
              <option value="">Select a state</option>
              {Object.keys(state_array).map((prop, key) => (
                <option key={key} value={prop}>
                  {state_array[prop]}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Zip Code:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="zip"
              maxLength={5}
              pattern="^\d{5}$"
              value={address?.zip || ""}
              onChange={handleChangeIndividualAddress}
              onInvalid={() => onError("Zip")}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            County:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="county"
              // pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={address?.county || ""}
              onChange={handleChangeIndividualAddress}
              onInvalid={() => onError("County")}
            />
          </Col>
        </Form.Group>
      </div>
    </>
  );
}
